import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";

export function BottomDock(props) {
  const links = [
    {
      url: "/who-we-are",
      title: "ABOUT US",
      icon: "who-we-are-icon.png",
      iconHover: "who-we-are-icon.png",
    },
    {
      url: "/what-is-the-point-of-freemasonry",
      title: "FREEMASONRY",
      icon: "freemasonry-icon.png",
      iconHover: "freemasonry-icon.png",
    },
    {
      url: "/why-you-should-join",
      title: "WHY JOIN?",
      icon: "why-join-icon.png",
      iconHover: "why-join-icon.png",
    },
    {
      url: "/how-to-join",
      title: "HOW TO JOIN",
      icon: "sign-up-icon.png",
      iconHover: "sign-up-icon.png",
    },
    {
      url: "/faq",
      title: "FAQ",
      icon: "faq-icon.png",
      iconHover: "faq-icon.png",
    },
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li bottomMenu__li--${index} ${
        isCurrent(link.url) ? "selected" : ""
      }`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className='bottomMenu'>
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
    </>
  );
}
