/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../cms/utils/cmsUtils";
import { useLayoutEffect } from "react";
//import style from './style';

export function JotformIframe(props) {
  const item = props.item;
  const cssClass = props.cssClass;
  const code = cmsUtils.payload(item, "Code") || "";

  const javaScriptCode = code
    .substring(code.indexOf("<_script"), code.indexOf("</_script>") + 10)
    .trim();
  const nonjavaScriptCode = code.replace(javaScriptCode, "").trim();

  useLayoutEffect(() => {
    const existingScript = document.getElementById("jotformIframe");
    if (existingScript) {
      document.body.removeChild(existingScript);
    }

    const javaScriptInsideCode = javaScriptCode
      .substring(
        javaScriptCode.indexOf(">") + 1,
        javaScriptCode.indexOf("</_script>")
      )
      .trim();

    const script = document.createElement("script");
    script.type = `text/javascript`;
    script.text = javaScriptInsideCode;
    script.id = "jotformIframe";

    document.body.appendChild(script);

    // eslint-disable-next-line
  }, []);

  return (
    <div className={cssClass} id={item.anchorName}>
      {nonjavaScriptCode && nonjavaScriptCode !== "" && (
        <div dangerouslySetInnerHTML={{ __html: nonjavaScriptCode }}></div>
      )}
    </div>
  );
}
